<template>
  <div class="gigya">
    <h1>Gigya integration page</h1>
    <div v-if="jwt">
      Token from Gigya:
      <pre>{{ jwt }}</pre>
      <br />
      ConsumerId from Gigya:
      <pre>{{ consumerId }}</pre>
    </div>
    <div v-else>
      Trying to access Gigya and getting the token...
      <div>
        <a :href="DCE_LOGIN_URL" target="_blank">Login First please</a>
      </div>
    </div>
    <br />
    <div v-if="email">
      Email from Gigya:
      <pre>{{ email }}</pre>
    </div>
    <br />
    <div v-if="consumerId">
      <div v-if="consumer?.firstName">
        Customer Info from the API:
        <pre class="text-xs">{{ JSON.stringify(consumer, null, '\t') }}</pre>
        <div class="mt-2">
          <p>Successful login!</p>
          <Button href="/rewardshop" outline="true">
            Go back to the shop
          </Button>
          <Button outline="true" @click="logOut"> Logout </Button>
          <!-- <router-link :to="shopRoute" class="mb-3 px-3">Go back to the shop</router-link> -->
        </div>
      </div>
      <span v-else> Fetching the consumer info: </span>
      <span v-if="consumerFetchError">{{ consumerFetchError }}</span>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import Button from '@/components/Button/Button';
import { DCE_LOGIN_URL } from '../constants';

export default defineComponent({
  name: 'Home',
  components: { Button },
  setup() {
    const store = useStore()
    onMounted(() => {
      // since the gigya integration happens before entering the route
      // we can call directly the getConsumer action on mount
      store.dispatch('consumerModule/getConsumer')
    })
    return {
      logOut: () => store.dispatch('authModule/logout'),
      jwt: computed(() => store.state.authModule.user.jwt),
      email: computed(() => store.state.authModule.user.email),
      consumerId: computed(() => store.state.authModule.user.uid),
      consumer: computed(() => store.state.consumerModule.consumer),
      error: computed(() => store.state.consumerModule.consumerFetchError),
      DCE_LOGIN_URL,
    }
  }
});
</script>
